import axios from 'axios';

const API_VERSION_HEADER = process.env.API_VERSION_HEADER || 'v11';
const API_URL = process.env.GATSBY_API_URL || 'https://test-api1.periodica.press/api';
const defaultHeaders = { 'Accept-Version': API_VERSION_HEADER, 'Content-Type': 'application/json' };

export type PostFeedBackArgs = {
  email: string;
  phone: string;
  vote: number;
  order?: number;
  fullname?: string;
  comment?: string;
  selectedTags?: Array<string>;
};

export const postFeedBack = async ({
  email,
  phone,
  vote,
  order,
  fullname,
  comment,
  selectedTags,
}: PostFeedBackArgs): Promise<void> => {
  try {
    await axios.post(
      `${API_URL}/feedback`,
      {
        email,
        phone,
        vote,
        order,
        full_name: fullname,
        comment,
        source: 'site_email_delivered',
        tags: selectedTags,
      },
      {
        headers: defaultHeaders,
      }
    );
  } catch (error: any) {
    throw new Error('feedback service request error:', error);
  }
};
