import React from 'react';
import { OneColumn } from '@components/Layouts/OneColumn';
import { Heading as H, Paragraph as P } from '@periodica/ui-kit';
import SEO from '@components/Layouts/SEO';

import styles from './ReviewFormContainer.module.scss';

type ReviewFormContainerArgs = {
  title: string;
  description: string;
};

export function ReviewFormContainer({
  title,
  description,
  children,
}: React.PropsWithChildren<ReviewFormContainerArgs>) {
  return (
    <OneColumn>
      <SEO
        title="Оставьте свой отзыв о работе Периодики"
        description="Мы обрабатываем каждый отзыв и учитываем ваши пожелания в работе."
      />
      <div className={styles.main}>
        <div className={styles.header}>
          <H>{title}</H>
          <P size="large">{description}</P>
        </div>
        {children}
      </div>
    </OneColumn>
  );
}
