import React, { useState } from 'react';
import { Text as TextField, Button } from '@periodica/ui-kit';
import type { ReviewFormArgs } from './types';
import { ReviewFormContainer } from './ReviewFormContainer';

import styles from './PositiveReviewForm.module.scss';

export function PositiveReviewForm({
  order,
  vote,
  phone,
  fullname,
  email,
  onSubmit,
  isLoading,
}: ReviewFormArgs) {
  const [comment, setComment] = useState<string>('');

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!comment) return;

    onSubmit({
      phone,
      vote,
      fullname,
      email,
      order,
      comment,
    });
  };

  return (
    <ReviewFormContainer
      title="Спасибо за высокую оценку"
      description="Рады, что всё прошло хорошо. Нам важно знать ваше мнение. Будем благодарны, если вы
      оставите отзыв о нашем сервисе."
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <input type="hidden" name="vote" defaultValue={vote} />
        <input type="hidden" name="email" defaultValue={email} />
        <input type="hidden" name="phone" defaultValue={phone} />
        <input type="hidden" name="vote" defaultValue={fullname} />
        <input type="hidden" name="order" defaultValue={order} />
        <TextField
          name="comment"
          label="Комментарий"
          inputProps={{ rows: 4 }}
          value={comment}
          onChange={setComment}
        />
        <Button size="x2Large" type="submit" className={styles.buttonStyles} disabled={isLoading}>
          Отправить
        </Button>
      </form>
    </ReviewFormContainer>
  );
}
